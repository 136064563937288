body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; /* Gör att html och body sträcker sig över hela viewporten */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background {
  background-image: url('./Img/w3.jpg'); /* Ange sökvägen till din bakgrundsbild */
  background-size: cover; /* Gör så att bilden täcker hela skärmen */
  background-repeat: no-repeat; /* Förhindra att bilden upprepas */
  background-position: center; /* Centrera bilden */
  background-attachment: fixed;

  position: fixed; /* Använd absolut positionering istället för fixed */
  top: 0; /* Placera elementet högst upp */
  left: 0; /* Placera elementet längst till vänster */
  width: 100%; /* Gör elementet lika brett som viewporten */
  height: 100%; /* Gör elementet lika högt som viewporten */
  z-index: -1; /* Lägger bakgrunden bakom övrigt innehåll */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
