
.winecellar-background {
    background-image: url('/src/Img/winecellar.jpg'); 
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center; 
    background-attachment: fixed;
  
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    z-index: -1; 
  }

  /* Use to make the image darker */
  .winecellar-background::before { 
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity to control darkness */
    z-index: 1;
  }