.App {
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.InfoContainer {
  background-color: #ffffff; 
  align-items: center;
  margin: 0 auto;
  width: 90%; 
  max-width: 800px;
  margin-top: 10px;
  opacity: 0.95;
  align-content: center;
  border-radius: 10px;
  opacity: 0.8;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.GrassCutterToastContainer {
  background-color: #ffffff; 
  align-items: center;
  margin: 0 auto;
  width: 90%; 
  max-width: 800px;
  margin-top: 10px;
  opacity: 0.95;
  align-content: center;
  border-radius: 10px;
}
